export default {
    questionType: [
        { serial: 1, text: 'Multiple Choice', component: 'multiple-choice', icon: 'multiple.svg' },
        { serial: 2, text: 'Rating Scale', component: 'rating-scale', icon: 'rating_scale.svg' },
        { serial: 3, text: 'Rating', component: 'rating', icon: 'rating.svg' },
        { serial: 4, text: 'Selection', component: 'selection', icon: 'selection.svg' },
        { serial: 5, text: 'Text Type', component: 'text-type', icon: 'text_type.svg' },
        { serial: 6, text: 'Dropdown', component: 'dropdown', icon: 'dropdown.svg' },
        { serial: 7, text: 'Order Ranking', component: 'order-ranking', icon: 'order_ranking.svg' },
        { serial: 8, text: 'Slider Matrix', component: 'slider', icon: 'slider_matrix.svg' },
        { serial: 9, text: 'Date/Time', component: 'date-time', icon: 'date_time.svg' },
    ]
}
